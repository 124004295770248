<template>
  <div>
    <v-container class="my-5">
      <v-layout row wrap>
        <v-flex
          xs6
          md3
          lg2
          v-for="team in $store.state.teams.data"
          :key="team.id"
        >
          <v-card flat class="text-center">
            <v-icon x-large @click="teamDetail(team)">
              {{ team.icon }}
            </v-icon>
            <p class="mt-3">{{ team.name_short }}</p>
            <!-- <v-card-text >
              <p>{{ team.name_short }}</p>
            </v-card-text> -->
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Teams2",
  methods: {
    teamDetail(team) {
      console.log("click, click, click", team);
      this.$router.push(`/equipos/${team.id}`);
    },
  },
};
</script>

<style scoped>
p {
  font-family: "Spartan", sans-serif;
  font-weight: 600;
  font-size: 0.65rem;
  text-transform: uppercase;
}

.v-icon {
  color: black;
}

.v-icon:hover {
  color: #1b5e20;
}
</style>
